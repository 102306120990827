/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateOctank = /* GraphQL */ `
  subscription OnCreateOctank(
    $item_id: String
    $call_id: String
    $transcript_output: String
    $translated_lang: String
    $timestamp_created: AWSTimestamp
  ) {
    onCreateOctank(
      item_id: $item_id
      call_id: $call_id
      transcript_output: $transcript_output
      translated_lang: $translated_lang
      timestamp_created: $timestamp_created
    ) {
      item_id
      call_id
      transcript_output
      translated_lang
      timestamp_created
    }
  }
`;
export const onUpdateOctank = /* GraphQL */ `
  subscription OnUpdateOctank(
    $item_id: String
    $call_id: String
    $transcript_output: String
    $translated_lang: String
    $timestamp_created: AWSTimestamp
  ) {
    onUpdateOctank(
      item_id: $item_id
      call_id: $call_id
      transcript_output: $transcript_output
      translated_lang: $translated_lang
      timestamp_created: $timestamp_created
    ) {
      item_id
      call_id
      transcript_output
      translated_lang
      timestamp_created
    }
  }
`;
export const onDeleteOctank = /* GraphQL */ `
  subscription OnDeleteOctank(
    $item_id: String
    $call_id: String
    $transcript_output: String
    $translated_lang: String
    $timestamp_created: AWSTimestamp
  ) {
    onDeleteOctank(
      item_id: $item_id
      call_id: $call_id
      transcript_output: $transcript_output
      translated_lang: $translated_lang
      timestamp_created: $timestamp_created
    ) {
      item_id
      call_id
      transcript_output
      translated_lang
      timestamp_created
    }
  }
`;
export const testSubscription = /* GraphQL */ `
subscription MySubscription ($call_id: String!, $translated_lang: String!) {
  onCreateOctank(call_id: $call_id, translated_lang: $translated_lang) {
    transcript_output
  }
}
`;
