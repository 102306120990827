import { useEffect, useState } from 'react'
import logo from './logo.svg'
import './App.css'
import { API } from 'aws-amplify'
import { testSubscription } from './graphql/subscriptions'

var queryString = decodeURIComponent(window.location.search);
queryString = queryString.substring(1);
var queries = queryString.split("&");
var call_id = queries[0]
var translated_lang = queries[1]

function App() {
  const [message, updateMessage] = useState("No translated messages yet.")
  useEffect(() => {
    subscribe()
  }, [])
  function subscribe() {
    API.graphql({
      query: testSubscription,
      variables: {
        "call_id": call_id,
        "translated_lang": translated_lang
      }
    })
    .subscribe({
      next: messageData => {
        updateMessage(messageData.value.data.onCreateOctank.transcript_output)
        console.log(updateMessage)
      }
    })
  }
  return (
    <div className="App">
      {
        message && <h3>{message}</h3>
      }
      </div>
    )
}

export default App