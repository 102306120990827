import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Amplify from 'aws-amplify';

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Identity Pool ID
    //userPoolId: 'us-east-1_Orodsda2i',
    // REQUIRED - Amazon Cognito Region
    //region: 'us-east-1',
    // OPTIONAL - Amazon Cognito User Pool ID
    //identityPoolId: 'us-east-1:kv1348da-7474-4a01-029d-0b50475284895',
    // OPTIONAL - Amazon Cognito Web Client ID
    //userPoolWebClientId: 'gvj78fkasbi8aidsf9fuslanvxpa',
  },
  API: {
    'aws_appsync_graphqlEndpoint': 'https://epwlswj73ferbckqznubcs376i.appsync-api.us-east-1.amazonaws.com/graphql',
    'aws_appsync_region': 'us-east-1',
    'aws_appsync_authenticationType': 'API_KEY',
    'aws_appsync_apiKey': 'da2-xvkiw2e3hvg7lfao5xiubx7hhe',
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
